

































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { getAPI } from "@/api/axios-base";
import omitBy from "lodash/omitBy";
import set from "lodash/set";
import omit from "lodash/omit";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import BtnUploadDocument from "./buttons/BtnUploadDocument.vue";

import Carrousel from "@/components/Images/Carrousel.vue";
import { mapState } from "vuex";

const initialState = {
  evaluation: {
    wasVirtual: true,
    details: "",
  },
  medicalHistory: "",
  surgeryPreOpOrder: "",
  labs: "",
  ekg: "",
  otherMedicalRecord: "",
};

export default Vue.extend({
  components: { BtnUploadDocument, Carrousel },
  name: "check-list",
  props: {
    procedureType: String,
  },

  data() {
    return {
      loadingUpload: false,
      valueupload: 0,
      current: 0,
      loading: false,
      checkListForm: initialState,
      files: [],
      checkList: [
        {
          title: "Demographics",
        },
        {
          title: "Evaluation",
        },
        {
          title: "Medical History",
        },
        {
          title: "Surgery Pre-Op Order",
        },
        {
          title: "Labs",
        },
        {
          title: "EKG",
        },
        {
          title: "Other Medical Records",
        },
        {
          title: "Patient Contract",
        },
        {
          title: "Consents",
        },
        // {
        //   title: "Photo autorization Form",
        // },
        {
          title: "Surgical Procedure Consents",
        },
      ],
    };
  },
  destroyed() {
    this.files = [];
  },
  mounted() {
    this.files = [];
  },
  computed: {
    ...mapState("crmMedicFormModule", ["procedure"]),
  },
  watch: {
    procedure: {
      deep: true,
      immediate: true,
      handler: function (procedure: any) {
        this.resetValues();

        if (procedure != null) {
          if (
            this.procedure.evaluation != null ||
            this.procedure.evaluation != undefined
          ) {
            this.checkListForm.evaluation.wasVirtual = (
              this as any
            ).procedure.evaluation.wasVirtual;
            this.checkListForm.evaluation.details =
              (this as any).procedure.evaluation.details || "";
          }

          if ((this as any).procedure.medicalHistory != null) {
            this.checkListForm.medicalHistory =
              (this as any).procedure.medicalHistory.details || "";
          }
          if ((this as any).procedure.surgeryPreOpOrder != null) {
            this.checkListForm.surgeryPreOpOrder =
              (this as any).procedure.surgeryPreOpOrder.details || "";
          }
          if ((this as any).procedure.labs != null) {
            this.checkListForm.labs =
              (this as any).procedure.labs.details || "";
          }
          if ((this as any).procedure.ekg != null) {
            this.checkListForm.ekg = (this as any).procedure.ekg.details || "";
          }
          if ((this as any).procedure.otherMedicalRecord != null) {
            this.checkListForm.otherMedicalRecord =
              (this as any).procedure.otherMedicalRecord.details || "";
          }
        }

        this.checkField("evaluation");
        this.checkField("medicalHistory");
        this.checkField("surgeryPreOpOrder");
        this.checkField("labs");
        this.checkField("ekg");
      },
    },
  },
  methods: {
    async update(field: string, value: any): Promise<any> {
      let body = Object.assign({}, this.checkListForm);
      const procedureId = this.procedure.id;

      /*   if (field != "evaluation") {
        (body as any) = omit(body, ["evaluation"]);
        // Set only the field to update
        set(body, field, value);
        // Remove empty fields
        (body as any) = omitBy(body, (item) => item == "");
      } else {
        (body as any) = { evaluation: { ...this.checkListForm.evaluation } };
      } */

      this.loading = true;
      try {
        await getAPI.put(`/patient/updateCheckList/${procedureId}`, body);
        this.loading = false;
        if (this.files.length != 0) {
          this.loadingUpload = true;
          this.files.forEach((file: any, index) => {
            if (file !== null && file.size != 0) {
              const formData = new FormData();

              formData.append("file", file);
              getAPI
                .post(
                  `/patient/uploadFileCheckList/${this.procedure[field].uuid}/${field}`,
                  formData
                )
                .then(() => {
                  this.valueupload = (index * 100) / this.files.length;
                })
                .catch((error) => {
                  let mess = error.response.data.message;

                  if (mess.includes("[")) {
                    mess = mess.replace("[", "");
                    mess = mess.replace("]", "");
                  }
                  notifyError(
                    error.response.data,
                    `An error occurred: ${mess}`
                  );
                });
            }
          });
          this.resetValues();
          notifyInfo("Updated successfully");
          this.$emit("update-patient");
        }
      } catch (error: any) {
        this.loading = false;
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
      this.loadingUpload = false;
      this.valueupload = 0;
    },
    // Verify if the field is valid to set in "check" status
    checkField(field: string | null): boolean {
      if (field == null || field == "" || field == undefined) {
        return false;
      }

      return true;
    },
    resetValues() {
      this.checkListForm.evaluation.details = "";
      this.checkListForm.evaluation.wasVirtual = true;
      this.checkListForm.medicalHistory = "";
      this.checkListForm.ekg = "";
      this.checkListForm.labs = "";
      this.checkListForm.otherMedicalRecord = "";
      this.checkListForm.surgeryPreOpOrder = "";
      this.files = [];
    },
  },
});
